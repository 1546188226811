import React from 'react'
import { css } from 'linaria'
import Img from '../../components/NonStretchedImage'
import { colors } from '../../utils/theme'
import rhythm from '../../utils/rhythm'
import { transition } from '../../utils/theme'
import Location from './Location'
import breakpoints from '../../utils/tokens/breakpoints'

const hiddenContent = css`
  background: ${colors.accent.purple};
  position: absolute;
  width: 100%;
  max-width: 190px;
  height: calc(100% - 10px);
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  top: 0;
  border-radius: 8px;
  padding: ${rhythm(1 / 2)};
  color: #fff;
  opacity: 0;
  visibility: hidden;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  transition: all ${transition.speed.default} ${transition.curve.default};
  will-change: opacity, visibility;

  @media (min-width: ${breakpoints.md}) {
    padding: ${rhythm(1)};
    height: calc(100% - 20px);
  }

  p {
    margin-top: auto;
    margin-bottom: 0;
    width: 100%;
  }
`

const container = css`
  position: relative;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &:hover > div > div {
    opacity: 1;
    visibility: visible;
  }

  @media (min-width ${breakpoints.md}) {
    height: 15.15vw;
    min-height: 240px; /* todo */
  }

  .gatsby-image-wrapper {
    width: 100%;
  }

  .location {
    background: #fff;
    border-radius: 6px;
    color: ${colors.text.lightGray};
    position: absolute;
    padding: 0.375rem 0.5rem;
    line-height: 1.25;
    bottom: var(--loc-bottom);
    opacity: 0;
    transform: scale(0.9) translate3d(0, 4px, 0);
    backface-visibility: hidden;
    transition: all ${transition.speed.default} ${transition.curve.scaleUp};
    will-change: opacity, transform;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);

    span {
      color: ${colors.text.darkGray};
    }

    &::before {
      content: '';
      width: 13px;
      height: 13px;
      background: #2e0276;
      display: block;
      border-radius: 100%;
      position: absolute;
    }
  }

  .name {
    transform: translate3d(0, 4px, 0);
    transition: all ${transition.speed.default} ${transition.curve.scaleUp};
    will-change: opacity, transform;

    span {
      opacity: 0.7;
    }
  }

  &:hover {
    .location {
      opacity: 1;
      transform: scale(1) translate3d(0, 0, 0);
    }

    .name {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`

const nameClass = css`
  color: ${colors.text.darkGray};

  small {
    color: ${colors.text.lightGray};
  }
`

const avatar = css`
  width: 100%;
  position: relative;
`

const Member = ({ name, role, city, country, image }) => {
  return (
    <div className={container}>
      <div className={avatar}>
        <Img fluid={image.childImageSharp.fluid} alt={name} />
        <div className={hiddenContent}>
          <Location country={country} city={city} />
          {/* <p className="name">
          {name}
          <br />
          <span>{role}</span>
        </p> */}
        </div>
      </div>

      <p className={nameClass}>
        {name}
        <br />
        <small>{role}</small>
      </p>
    </div>
  )
}
export default Member
