import React from 'react'
import { css } from 'linaria'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from 'components/Layout'
import BecomeOneOfUs from '../components/Footer/BecomeOneOfUs'

import { HeroHeading, Subheading, Lead } from '../components/TextElements'
import Button from 'components/Button'
import Section from 'components/Section'
import SectionContent from 'components/Section/Content'

import Hero from '../elements/Hero'
import TechnologiesList from '../elements/TechnologiesList'
import TeamMember from '../elements/Team/Member'
import IconBox from '../elements/IconBox'

import rhythm from '../utils/rhythm'
import breakpoints from '../utils/tokens/breakpoints'
import { colors } from '../utils/theme'

import RetreatPhotos from '../elements/ReatreatPhotos'

const ogImage = '/img/og/about-og.jpg'

const heroContent = (
  <div
    className={css`
      ${{
        [`@media (min-width: ${breakpoints.lg})`]: {
          paddingLeft: rhythm(1),
          paddingRight: rhythm(1)
        }
      }};
    `}
  >
    <Subheading>About us</Subheading>
    <HeroHeading>Ninjas in Bejamas</HeroHeading>
    <Lead>
      We are a team of passionate web developers who share the vision of
      websites, that are high-performing, safe, and easy to maintain.
    </Lead>
    <Button to="/about/#team">Meet the Team</Button>
  </div>
)

const Page = ({ location, data }) => (
  <Layout
    location={location}
    title="The team behind your next JAMstack projects"
    description="We are a team of passionate web developers who share the vision of websites, that are high-performing, safe, and easy to maintain. Take a look at the team behind your next JAMstack project."
    image={ogImage}
    preLinksComponent={<BecomeOneOfUs />}
  >
    <Hero
      content={heroContent}
      image={data.hero}
      particles={
        <>
          <Img
            fixed={data.p12.childImageSharp.fixed}
            className={css`
              ${{
                bottom: '-25%',
                right: '-4vw',
                [`@media (min-width: ${breakpoints.xxl})`]: {
                  right: 'calc((-100vw + 87.5rem) / 2)'
                },
                [`@media (max-width: ${breakpoints.lg})`]: {
                  display: 'none !important'
                }
              }};
            `}
            style={{ position: 'absolute' }}
          />
          <Img
            fixed={data.p4.childImageSharp.fixed}
            className={css`
              ${{
                bottom: '-16%',
                right: '0',
                [`@media (min-width: ${breakpoints.lg})`]: {
                  bottom: '-6%',
                  right: '14%'
                },
                [`@media (max-width: ${breakpoints.md})`]: {
                  display: 'none !important'
                }
              }};
            `}
            style={{ position: 'absolute' }}
          />
          <Img
            fixed={data.p1.childImageSharp.fixed}
            className={css`
              ${{
                bottom: '0%',
                right: '50%',
                [`@media (max-width: ${breakpoints.lg})`]: {
                  display: 'none !important'
                }
              }};
            `}
            style={{ position: 'absolute' }}
          />
        </>
      }
    />
    <Section
      className={css`
        ${{
          textAlign: 'center',
          paddingTop: rhythm(2),
          [`@media (min-width: ${breakpoints.md})`]: {
            paddingTop: rhythm(4)
          }
        }};
      `}
      header={
        <>
          <h2>It’s nice to meet you</h2>
          <Lead>
            We are a team of passionate web developers who share the vision of
            websites, that are high-performing, safe, and easy to maintain. We
            make this vision happening by building serverless Progressive Web
            Apps and websites on the JAMstack architecture.
          </Lead>
        </>
      }
    />
    <Section
      variant="lightGray"
      id="team"
      header={
        <>
          <Subheading>Meet the Ninjas</Subheading>
          <h2>Bejamas Team</h2>
          <Lead>
            Working from all around the world to build the Web of tomorrow
          </Lead>
        </>
      }
    >
      <SectionContent
        rowGap="1"
        columnGap="1"
        className={css`
          ${{
            gridTemplateColumns:
              'repeat(auto-fill, minmax(calc(10vw + 5rem), 4fr))',
            marginTop: rhythm(2),
            maxWidth: rhythm(44)
          }};
        `}
      >
        {data.members.edges &&
          data.members.edges.map(({ node }) => (
            <TeamMember key={node.id} {...node} />
          ))}
      </SectionContent>
    </Section>
    <Section
      header={
        <>
          <Subheading>Things we value</Subheading>
          <h2>Believability at the core</h2>
          <Lead>
            As a remote team of independent thinkers, we share the same values
            that make things work like a charm.
          </Lead>
        </>
      }
      footer={
        <div
          className={css`
            ${{ maxWidth: rhythm(26), margin: '0 auto' }}
          `}
        >
          <Lead>
            For us customers really do come first because we help them not only
            grow their businesses but also educate them to understand the tech
            behind it.
          </Lead>
          <Button to={'/estimate-project/'}>Get Free Advice</Button>
        </div>
      }
    >
      <SectionContent
        columnGap={`calc(6vw + ${rhythm(2)})`}
        className={css`
          ${{
            gridTemplateColumns: 'repeat(auto-fit, minmax(16rem, 1fr))'
          }};
        `}
      >
        <IconBox>
          <Img
            fixed={data.transparency.childImageSharp.fixed}
            alt="Transparency and Truthfulness"
          />
          <h3>Transparency and Truthfulness</h3>
          <p>
            We communicate with each other honestly, even if the truth violates
            one's ego. We believe that in the long term only through good and
            honest communication with each other we can generate added value for
            our clients.
          </p>
        </IconBox>
        <IconBox>
          <Img
            fixed={data.openness.childImageSharp.fixed}
            alt="Openness of mind"
          />
          <h3>Openness of mind</h3>
          <p>
            In our case transparency means that everyone's opinion is open for
            discussion by anyone on the team. That is why it is so important for
            us to keep our mind open and treat every feedback with humility, a
            chance to look at things from another perspective, and as an
            opportunity to improve our skills.
          </p>
        </IconBox>
        <IconBox>
          <Img
            fixed={data.commitment.childImageSharp.fixed}
            alt="Commitment and Responsibility"
          />
          <h3>Commitment and Responsibility</h3>
          <p>
            Remote work gives great possibilities, but comes with great
            responsibility. At Bejamas, each team member is responsible for a
            certain part of a project and it’s on them to deliver the best
            solution for it. For us it’s crucial to keep things this way and run
            projects as a well-oiled machine.
          </p>
        </IconBox>
        <IconBox>
          <Img
            fixed={data.empathy.childImageSharp.fixed}
            alt="Empathy and Unselfishness"
          />
          <h3>
            Empathy and <br />
            Unselfishness
          </h3>
          <p>
            Those are two very important factors at Bejamas. We believe that
            every person deserves valuable relationships with other people and a
            pleasant work environment. We try to help each other and give as
            much as we take ourselves.
          </p>
        </IconBox>
      </SectionContent>
    </Section>
    <Section
      variant="lightGray"
      header={
        <>
          <Subheading>Technologies we use</Subheading>
          <h2>Beautifying the web</h2>
          <Lead>
            Every project is a new story, yet some things stay the same. We pick
            a dedicated set of tools for each one, for the best performance
            possible.
          </Lead>
        </>
      }
      footer={<Button to={'/estimate-project/'}>Consult your project</Button>}
    >
      <TechnologiesList />
    </Section>
    <Section>
      <SectionContent
        rowGap="0"
        columnGap={`calc(6vw + ${rhythm(2)})`}
        className={css`
          ${{
            marginTop: rhythm(1)
          }};
        `}
      >
        <div>
          <Subheading>Digital nomads</Subheading>
          <h2>Behind the scenes of remote work</h2>
          <Lead>
            Who said we have to work from one office to be a close-knit team?
          </Lead>
        </div>
        <div
          className={css`
            ${{
              color: colors.text.lightGray,
              [`@media (min-width: ${breakpoints.md})`]: {
                marginTop: rhythm(5 / 2)
              }
            }};
          `}
        >
          <p>
            The mere fact we’re all different and have different lifestyles
            gives us a chance to be more open and inspires us to make progress.
            We don’t believe in the nine-to-five work routine, being tied up to
            the desk, or forcing new employees to relocate.
          </p>
          <p>
            We see the jobs of the future more our way - being ourselves, being
            free, and living our dreams. There are things Slack can’t help with,
            that’s why we regularly take trips, coming from different parts of
            the world and having real fun.
          </p>
        </div>
      </SectionContent>
      <RetreatPhotos />
    </Section>
  </Layout>
)

export default Page

export const query = graphql`
  query AboutPageQuery {
    hero: file(
      relativePath: { regex: "/img/about/" }
      name: { eq: "about-hero@2x" }
    ) {
      childImageSharp {
        fluid(maxWidth: 660, traceSVG: { color: "#0a0116" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    p1: file(relativePath: { regex: "/img/particles/" }, name: { eq: "1" }) {
      childImageSharp {
        fixed(width: 160, traceSVG: { color: "#0a0116" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    p4: file(relativePath: { regex: "/img/particles/" }, name: { eq: "4" }) {
      childImageSharp {
        fixed(width: 160, traceSVG: { color: "#0a0116" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    p12: file(
      relativePath: { regex: "/img/particles/" }
      name: { eq: "12-cutted" }
    ) {
      childImageSharp {
        fixed(width: 250, traceSVG: { color: "#0a0116" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    members: allTeamYaml {
      edges {
        node {
          id
          name
          role
          city
          country
          image {
            childImageSharp {
              fluid(maxWidth: 190, traceSVG: { color: "#110328" }) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
              }
            }
          }
        }
      }
    }
    transparency: file(
      relativePath: { regex: "/img/about/" }
      name: { eq: "01_Transparency_Icon" }
    ) {
      childImageSharp {
        fixed(height: 200, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    openness: file(
      relativePath: { regex: "/img/about/" }
      name: { eq: "02_Openness_of_Mind_Icon" }
    ) {
      childImageSharp {
        fixed(height: 200, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    commitment: file(
      relativePath: { regex: "/img/about/" }
      name: { eq: "03_Commitment_Responsibility_Icon" }
    ) {
      childImageSharp {
        fixed(height: 200, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    empathy: file(
      relativePath: { regex: "/img/about/" }
      name: { eq: "04_Empathy_Icon" }
    ) {
      childImageSharp {
        fixed(height: 200, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`
